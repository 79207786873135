import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { SwapHorizontalCircleOutlined } from '@material-ui/icons';
import { format } from '../../utils/datetime';
import PropsList, { IRow } from '../../components/PropsList';
import { prepareSingleTransaction, transaction } from '../../api/api';
import {
  TransactionTypes,
  TTransactionType,
} from '../../const/transactionType';
import {
  TTransactionStatus,
  TransactionStatus,
} from '../../const/transactionStatus';
import { TOKEN } from '../../const/token'

const Transaction: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const { id } = useParams<{ id: string }>();

  const isAccount = useCallback(({ type }: { type: TTransactionType }, row: 'from' | 'to') => {
    switch (type) {
      case 'MINT':
        return row === 'to'
      case 'BURN':
        return row === 'from'
      default: return true;
    }
  }, [])

  const getData = useCallback(async () => {
    const { transactions } = await transaction(id);

    setData(prepareSingleTransaction(transactions[0]));
  }, [setData, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const rows: IRow[] = useMemo(
    () => [
      {
        title: 'Hash',
        path: 'id',
      },
      {
        title: 'Type',
        path: 'type',
        format: (v: TTransactionType) => TransactionTypes[v]?.label,
      },
      {
        title: 'Status',
        path: 'status',
        format: (v: TTransactionStatus) => TransactionStatus[v]?.label,
      },
      {
        title: 'Index',
        path: 'index',
      },
      {
        title: 'Timestamp',
        path: 'time',
        format: (value: Date) => {
          return value && format(value);
        },
      },
      {
        title: 'From',
        path: 'from.id',
        renderValue: (value, data) =>  isAccount(data || {}, 'from') ? (
          <Typography>
            <Link to={`/account/${value}`}>{value}</Link>
          </Typography>
        ) : (
          <Typography>{value}</Typography>
        ),
      },
      {
        title: 'To',
        path: 'to.id',
        renderValue: (value, data) => isAccount(data || {}, 'to') ? (
          <Typography>
            <Link to={`/account/${value}`}>{value}</Link>
          </Typography>
        ) : (
          <Typography>{value}</Typography>
        ),
      },
      {
        title: 'Amount',
        path: 'amount',
        format: (value) => `${value || 0} ${TOKEN}`,
      },
      {
        title: 'Fee',
        path: 'fee',
        format: (value) => `${value || 0} ${TOKEN}`,
      },
      {
        title: 'Memo',
        path: 'memo',
      },
    ],
    [isAccount],
  );

  return (
    <Grid container direction="column">
      <Card>
        <CardContent>
          <Grid container item alignItems="center">
            <Typography variant="h5">Transaction</Typography>
            <SwapHorizontalCircleOutlined />
          </Grid>
          <Divider />
          <PropsList data={data} rows={rows} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Transaction;
