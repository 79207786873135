import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { get, map } from 'lodash';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { SwapHorizontalCircleOutlined, Eco as EcoIcon, Whatshot as WhatshotIcon  } from '@material-ui/icons';
import { transactionsDateFormat } from '../../utils/datetime';
import { TTransactionType } from '../../const/transactionType'
import { TOKEN } from '../../const/token'

interface ITransactionsTable {
  count: number;
  getData: (page: number, perPage: number) => any;
  isAccountLinkDisabled?: (value: string) => boolean;
}

const useStyles = makeStyles((theme) => ({
  disabledLink: {
    color: theme.palette.grey['600'],
  },
}));

const DEFAULT_PER_PAGE = 10;

const TransactionsTable: React.FC<ITransactionsTable> = ({
  count,
  getData,
  isAccountLinkDisabled,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE);

  const isAccount = useCallback(({ type }: { type: TTransactionType }, row: 'from' | 'to') => {
    switch (type) {
      case 'MINT':
        return row === 'to'
      case 'BURN':
        return row === 'from'
      default: return true;
    }
  }, [])

  const setPreparedData = useCallback(async () => {
    setIsLoading(true);
    const res = await getData(page, perPage);
    setData(res);
    setIsLoading(false);
  }, [getData, page, perPage]);

  useEffect(() => {
    setPreparedData();
  }, [setPreparedData]);

  const handleChangePage = useCallback(
    (e: React.MouseEvent | null, newPage: number) => {
      setPage(newPage);
    },
    [],
  );

  const handleChangePerPage = useCallback((e: React.ChangeEvent) => {
    setPerPage(get(e, 'target.value') || DEFAULT_PER_PAGE);
  }, []);

  const renderTransactionIcon = useCallback(({ type }: { type: TTransactionType }) => {
    switch (type) {
      case 'MINT':
        return <EcoIcon />
      case 'BURN':
        return <WhatshotIcon />
      default: return <SwapHorizontalCircleOutlined />
    }
  }, [])

  const renderTransactionCell = useCallback(
    (transaction) => (
      <ListItem dense>
        <ListItemIcon>
          {renderTransactionIcon(transaction)}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2">
              <Link to={`/transaction/${get(transaction, 'id')}`}>
                {get(transaction, 'id')}
              </Link>
            </Typography>
          }
          secondary={transactionsDateFormat(get(transaction, 'time'))}
        />
      </ListItem>
    ),
    [renderTransactionIcon],
  );

  const renderAccountLink = useCallback(
    (accountId) => <Link to={`/account/${accountId}`}>{accountId}</Link>,
    [],
  );

  const renderAccountsCell = useCallback(
    (transaction) => (
      <ListItem dense>
        <ListItemText
          primary={
            <Typography variant="body2">
              From:&nbsp;
              {!isAccount(transaction, 'from') || (isAccountLinkDisabled &&
              isAccountLinkDisabled(get(transaction, 'from.id'))) ? (
                <span className={classes.disabledLink}>
                  {get(transaction, 'from.id')}
                </span>
              ) : (
                renderAccountLink(get(transaction, 'from.id'))
              )}
            </Typography>
          }
          secondary={
            <Typography variant="body2">
              To:&nbsp;
              {!isAccount(transaction, 'to') || (isAccountLinkDisabled &&
              isAccountLinkDisabled(get(transaction, 'to.id'))) ? (
                <span className={classes.disabledLink}>
                  {get(transaction, 'to.id')}
                </span>
              ) : (
                renderAccountLink(get(transaction, 'to.id'))
              )}
            </Typography>
          }
        />
      </ListItem>
    ),
    [renderAccountLink, isAccountLinkDisabled, classes, isAccount],
  );

  const renderRow = useCallback(
    (transaction) => (
      <TableRow key={transaction.id}>
        <TableCell>{renderTransactionCell(transaction)}</TableCell>
        <TableCell>{renderAccountsCell(transaction)}</TableCell>
        <TableCell>
          {get(transaction, 'amount')}{' '}
          <Typography variant="caption">{TOKEN}</Typography>
        </TableCell>
      </TableRow>
    ),
    [renderTransactionCell, renderAccountsCell],
  );

  const renderNoDataFound = useMemo(
    () => (
      <Typography align="center" variant="body2">
        No transactions found
      </Typography>
    ),
    [],
  );

  const renderData = useMemo(
    () => (data && data.length ? map(data, renderRow) : renderNoDataFound),
    [data, renderRow, renderNoDataFound],
  );

  const renderLoader = useMemo(
    () => (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    ),
    [],
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>{isLoading ? renderLoader : renderData}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              component="td"
              count={count}
              onRowsPerPageChange={handleChangePerPage}
              onPageChange={handleChangePage}
              page={page}
              rowsPerPage={perPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TransactionsTable;
