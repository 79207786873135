import axios from 'axios';
import { map } from 'lodash';
import { TTransactionType } from '../const/transactionType'

export const NETWORK_IDENTIFIER = {
  blockchain: process.env.REACT_APP_BLOCKCHAIN,
  network: process.env.REACT_APP_NETWORK,
};

export const AMOUNT_DECIMALS = 100000000;

function prepareAccounts(transaction: any) {
  switch (transaction?.operations[0]?.type as TTransactionType) {
    case 'MINT':
      return {
        to: { id: transaction?.operations[0]?.account?.address },
        from: { id: 'Minting Account' },
        amount:
          parseInt(transaction?.operations[0]?.amount?.value) /
          AMOUNT_DECIMALS,
        type: transaction?.operations[0]?.type,
      }
    case 'BURN':
      return {
        from: { id: transaction?.operations[0]?.account?.address },
        to: { id: 'Minting Account' },
        amount:
          parseInt(transaction?.operations[0]?.amount?.value) /
          AMOUNT_DECIMALS * -1,
        type: transaction?.operations[0]?.type,
      }
    default:
      return {
        from: { id: transaction?.operations[0]?.account?.address },
        to: { id: transaction?.operations[1]?.account?.address },
        amount:
          parseInt(transaction?.operations[1]?.amount?.value) /
          AMOUNT_DECIMALS,
        type: transaction?.operations[0]?.type,
      }
  }
}

export function prepareTransactionsList(blocks: any[]) {
  return map(blocks, (block) => ({
    id: block.transactions[0]?.transaction_identifier?.hash,
    time: block.timestamp,
    ...prepareAccounts(block.transactions[0])
  }));
}

export function prepareSingleTransaction(data: any) {
  return {
    id: data?.transaction?.transaction_identifier?.hash,
    status: data?.transaction?.operations[0]?.status,
    index: data?.block_identifier?.index,
    time: data?.transaction?.metadata?.timestamp / 1000000,
    ...prepareAccounts(data?.transaction),
    fee:
      data?.transaction?.operations[2]?.amount?.value / (-1 * AMOUNT_DECIMALS),
    memo: data?.transaction?.metadata?.memo,
  };
}

export function prepareAccountTransactions(data: any) {
  return map(data, (tr) => ({
    id: tr.transaction?.transaction_identifier?.hash,
    time: tr?.transaction?.metadata?.timestamp / 1000000,
    ...prepareAccounts(tr.transaction),
  }));
}

export async function status() {
  const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/network/status`, {
    network_identifier: NETWORK_IDENTIFIER,
  });

  return data;
}

export async function block(index: number) {
  const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/block`, {
    network_identifier: NETWORK_IDENTIFIER,
    block_identifier: { index },
  });

  return data;
}

export async function transaction(hash: string) {
  const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/search/transactions`, {
    network_identifier: NETWORK_IDENTIFIER,
    transaction_identifier: { hash },
  });

  return data;
}

export async function account(address: string) {
  const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/account/balance`, {
    network_identifier: NETWORK_IDENTIFIER,
    account_identifier: { address },
  });

  return data;
}

export async function accountTransactions(address: string) {
  const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/search/transactions`, {
    network_identifier: NETWORK_IDENTIFIER,
    account_identifier: { address },
  });

  return data;
}
