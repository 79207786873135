export type TTransactionStatus = 'COMPLETED';

interface ITransactionStatus {
  key: TTransactionStatus;
  label: string;
}

export const COMPLETED: ITransactionStatus = {
  key: 'COMPLETED',
  label: 'Completed',
};

export const TransactionStatus: Record<TTransactionStatus, ITransactionStatus> =
  {
    COMPLETED: COMPLETED,
  };
