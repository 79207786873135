import {
  createTheme,
  responsiveFontSizes,
  Theme,
} from '@material-ui/core/styles';

export default function muiTheme(): Theme {
  const theme = createTheme({});

  return responsiveFontSizes(theme);
}
