import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Switch,
  makeStyles,
} from '@material-ui/core';
import TransactionsTable from '../../components/TransactionsTable';
import PropsList, { IRow } from '../../components/PropsList';
import { slice, orderBy } from 'lodash';
import {
  account,
  accountTransactions,
  AMOUNT_DECIMALS,
  prepareAccountTransactions,
} from '../../api/api';
import { TOKEN } from '../../const/token'

const useStyles = makeStyles((theme) => ({
  switch: {
    margin: `-${theme.spacing()}px`,
  },
  transactionsWrap: {
    marginTop: theme.spacing() * 3,
  },
}));

const Account: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const [showTransaction, setShowTransaction] = useState(false);
  const [data, setData] = useState<any>(null);
  const [transactionsCount, setTransactionsCount] = useState<any>(0);

  const getData = useCallback(async () => {
    const data = await account(id);
    setData({
      id,
      balance: data?.balances[0]?.value / AMOUNT_DECIMALS,
    });
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getAccountTransactions = useCallback(
    async (page, perPage) => {
      const { transactions: accTransactions, total_count } =
        await accountTransactions(id);
      setTransactionsCount(total_count);
      return slice(
        orderBy(prepareAccountTransactions(accTransactions), 'time', 'desc'),
        page * perPage,
        page * perPage + perPage,
      );
    },
    [id],
  );

  const handleSwitch = useCallback((e, v: boolean) => {
    setShowTransaction(v);
  }, []);

  const rows = useMemo<IRow[]>(
    () => [
      {
        title: 'Address',
        path: 'id',
      },
      {
        title: 'Balance',
        path: 'balance',
        format: (value) => `${value || 0} ${TOKEN}`,
      },
      {
        title: 'Transactions',
        path: 'transactions',
        renderValue: () => (
          <Switch
            onChange={handleSwitch}
            value={showTransaction}
            size="small"
            className={classes.switch}
            color="primary"
          />
        ),
      },
    ],
    [handleSwitch, showTransaction, classes],
  );

  const isAccountLinkDisabled = useCallback(
    (value) => value === data?.id,
    [data],
  );

  const renderTransactions = useMemo(
    () => (
      <Card className={classes.transactionsWrap}>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Transactions
          </Typography>
          <TransactionsTable
            getData={getAccountTransactions}
            count={transactionsCount || 0}
            isAccountLinkDisabled={isAccountLinkDisabled}
          />
        </CardContent>
      </Card>
    ),
    [classes, getAccountTransactions, isAccountLinkDisabled, transactionsCount],
  );

  return (
    <Grid container direction="column">
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Account
          </Typography>
          <PropsList data={data} rows={rows} />
        </CardContent>
      </Card>
      {showTransaction && renderTransactions}
    </Grid>
  );
};

export default Account;
