export type TTransactionType = 'TRANSACTION' | 'FEE' | 'MINT' | 'BURN';

interface ITransactionType {
  key: TTransactionType;
  label: string;
}

export const TRANSACTION: ITransactionType = {
  key: 'TRANSACTION',
  label: 'Transaction',
};

export const FEE: ITransactionType = {
  key: 'FEE',
  label: 'Fee',
};

export const MINT: ITransactionType = {
  key: 'MINT',
  label: 'Mint',
};

export const BURN: ITransactionType = {
  key: 'BURN',
  label: 'Burn',
};

export const TransactionTypes: Record<TTransactionType, ITransactionType> = {
  TRANSACTION: TRANSACTION,
  FEE: FEE,
  MINT: MINT,
  BURN: BURN,
};
