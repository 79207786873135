import { AppBar, Button, Grid, makeStyles, Toolbar } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './origynLogo.svg';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.mixins.toolbar.minHeight,
    width: '100%',
    padding: theme.spacing() * 3,
  },
  navLink: {
    color: '#000',
    textTransform: 'none',
  },
}));

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container item>
      <AppBar position="fixed" color="default">
        <Toolbar variant="dense">
          <Grid container justifyContent="space-between">
            <Link to="/">
              <img src={logo} />
            </Link>
            <Link to="/">
              <Button className={classes.navLink}>Transactions</Button>
            </Link>
          </Grid>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{children}</main>
    </Grid>
  );
};

export default Layout;
