import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from '../Layout';
import Transactions from '../../pages/Transactions';
import Transaction from '../../pages/Transaction';
import Account from '../../pages/Account';

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Layout>
        <Route exact path="/">
          <Transactions />
        </Route>
        <Route path="/transaction/:id">
          <Transaction />
        </Route>
        <Route path="/account/:id">
          <Account />
        </Route>
      </Layout>
    </Switch>
  </BrowserRouter>
);

export default Routes;
