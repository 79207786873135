import React, { useCallback } from 'react';
import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { get, map } from 'lodash';

export interface IRow {
  title: string;
  path: string;
  format?: (value: any, row?: any) => string | number;
  renderValue?: (value: any, row?: any) => Element | React.ReactElement;
}

interface IPropsList {
  data: any[];
  rows: IRow[];
}

const useStyles = makeStyles(() => ({
  row: {
    padding: '5px 0',
  },
}));

const PropsList: React.FC<IPropsList> = ({ rows, data }) => {
  const classes = useStyles();

  const renderRow = useCallback(
    ({ title, path, format, renderValue }: IRow) => {
      return (
        <Grid container item key={title}>
          <Grid container item className={classes.row}>
            <Grid item sm={3} xs={12}>
              <Typography variant="subtitle2">{title}</Typography>
            </Grid>
            <Grid item sm={9} xs={12}>
              {renderValue ? (
                renderValue(get(data, path), data)
              ) : (
                <Typography>
                  {format
                    ? format(get(data, path), data)
                    : get(data, path) || '-'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      );
    },
    [data, classes],
  );

  return (
    <Grid container item>
      {map(rows, renderRow)}
    </Grid>
  );
};

export default PropsList;
