import dayjs from 'dayjs';

export const DEFAULT_DATETIME_FORMAT = 'M/D/YYYY, H:mm:ss A z';

export function format(
  value: Date | string,
  template = DEFAULT_DATETIME_FORMAT,
): string {
  return value && `${dayjs(value).format(template)}`;
}

export function transactionsDateFormat(value: Date | string): string {
  return value && `${format(value)}, ${dayjs(value).fromNow()}`;
}
