import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { times } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { block, prepareTransactionsList, status } from '../../api/api';
import TransactionsTable from '../../components/TransactionsTable';

const Transactions: React.FC = () => {
  const [totalCount, setTotalCount] = useState<number>(0);

  const callStatus = useCallback(async () => {
    const data = await status();
    setTotalCount(data.sync_status.current_index);
  }, []);

  const callBlock = useCallback(async (index) => {
    return await block(index);
  }, []);

  const data = useCallback(
    async (page, perPage) => {
      const blocks = [];

      if (totalCount > -1) {
        const length = totalCount + 1 < perPage ? totalCount + 1 : perPage;

        for (const index of times(length)) {
          const block = await callBlock(totalCount - index - page * perPage);
          blocks.push(block.block);
        }
      }

      return prepareTransactionsList(blocks);
    },
    [callBlock, totalCount],
  );

  useEffect(() => {
    callStatus();
  }, [callStatus]);

  return (
    <Grid container direction="column">
      <Alert severity="info">
        <AlertTitle>
          The OGY Token is a token following an extended{' '}
          <a
            href="https://gist.github.com/dpori/380177f111899bfda59e0f17f620bdc5"
            target="_blank"
          >
            ICP Ledger Interface (view on gist)
          </a>
        </AlertTitle>
        It is deployed on the ICP on the canister{' '}
        <a
          href="https://ic.rocks/principal/jwcfb-hyaaa-aaaaj-aac4q-cai"
          target="_blank"
        >
          <strong>jwcfb-hyaaa-aaaaj-aac4q-cai (View on ic.rocks)</strong>
        </a>
      </Alert>
      <br />
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Transactions
          </Typography>
          <TransactionsTable getData={data} count={totalCount + 1} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Transactions;
