import React from 'react';
import { Grid } from '@material-ui/core';
import ThemeProvider from '../ThemeProvider';
import Routes from '../Routes';

function App(): React.ReactElement {
  return (
    <Grid container className="App">
      <ThemeProvider>
        <Routes />
      </ThemeProvider>
    </Grid>
  );
}

export default App;
